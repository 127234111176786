<template>
  <list-container-widget
    id="products"
    :title="widgetName"
    :app-id="3"
    stretch
    class="d-flex"
  >
    <template #actions>
      <!-- Panel actions -->
      <ViewAllButton item-type="products" />
    </template>
    <!-- Container with data -->
    <div v-if="items && items.length > 0 && canList">
      <div ref="container" class="x-scroll-container w-100" @scroll="onScroll">
        <!-- Items Loop -->
        <b-card
          v-for="(item, index) in items"
          :key="index"
          no-body
          class="x-scroll-element mx-1 mb-3 p-0 card-standard"
        >
          <b-link
            :to="canView ? { name: 'product', params: { id: item.key } } : null"
          >
            <!-- Image -->
            <b-card-body class="pb-2 d-block">
              <div class="item-img text-center">
                <b-img
                  :alt="`${item.name}`"
                  fluid
                  :src="getImageSrc(item.bgURL) || productPlaceholder"
                  class="card-image-medium"
                />
              </div>
              <!-- Product Details -->
              <b-card-text
                v-if="item.unitPrice && item.unitPrice > 0"
                class="min-height-1 w-100 mt-1"
              >
                <h5 class="precio font-weight-bold">
                  {{ item.unitPrice }} {{ item.currency.ISO }}
                  <!-- {{ item.currency.symbol }} -->
                </h5>
              </b-card-text>
              <div v-else class="min-height-1 mt-1" />

              <h4 class="text-ellipsis-lines-2 titulo">
                {{ item.name }}
              </h4>
              <b-card-text class="headline text-muted">
                {{ item.headline }}
              </b-card-text>
            </b-card-body>
          </b-link>
        </b-card>
        <!-- Buttons prev and next -->
        <button
          class="link-previous btn btn-icon btn-primary btn-previous"
          @click="scrollOnePage(-1)"
        >
          <b-spinner small class="spinner" />
        </button>
        <button
          class="link-next btn btn-icon btn-primary btn-next"
          :class="{ 'btn-next--loading': isLoadingNextPage }"
          @click="scrollOnePage(+1)"
        >
          <b-spinner small class="spinner" />
        </button>
      </div>
    </div>

    <!-- Loading -->
    <div v-else-if="isLoading">
      <b-spinner type="grow" small class="mx-auto mt-3 d-block" />
    </div>
    <b-row v-else-if="!canList" class="horizontal-placeholder">
      <b-col cols="12">
        <img :src="productsPlaceholder" />
      </b-col>
      <b-col cols="12">
        <p class="text-primary">
          {{ $t("no-permisions.message") }}
        </p>
      </b-col>
    </b-row>

    <!-- Container without data: Placeholder -->
    <b-row v-else class="horizontal-placeholder">
      <b-col cols="12">
        <img :src="productsPlaceholder" />
      </b-col>
      <b-col cols="12">
        <p class="text-primary">
          {{ $t("available.message", { itemName: widgetName }) }}
        </p>
      </b-col>
    </b-row>
    <description-addon v-model="isAppInfoModalVisible" :app-id="3" />
  </list-container-widget>
</template>

<script>
// import { ENABLED_APPS_GETTERS } from "@/store/enabled-apps/enabled-apps-store-constants";
import { getImageResource } from "@/@core/utils/image-utils";
// import { quillEditor } from "vue-quill-editor";
import DescriptionAddon from "@/@core/widgets/DescriptionAddon.vue";
// import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
import { checkPermissions } from "@/@core/utils/roles-utils";
import Service from "@/config/service-identifiers";
// import FileTypes from "@/@core/constants/FileTypes";
// import { CHAT_ACTIONS } from "@/views/apps/chat/constants/chat-store-constants";
// import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
// import Teleport from "vue2-teleport";
import ProductsPlaceholder from "@/assets/images/placeholders/light/projects.svg";
import ProductPlaceholder from "@/assets/images/placeholders/light/placeholder-dark.jpeg";
// import { translateTranslationTable } from "@/@core/libs/i18n/utils";
// import vSelect from "vue-select";
// import RequestMeetingModal from "../../member/modals/RequestMeetingModal.vue";

export default {
  name: "ProductsListWidget",
  components: {
    DescriptionAddon,
    // WidgetActions,
    ListContainerWidget,
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
  },
  mixins: [WidgetLayoutMixin],
  data() {
    return {
      isLoading: false,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      productInput: {
        currencyCode: "EUR",
      },
      formError: null,
      itemImage: null,
      imageSrc: null,
      isAppInfoModalVisible: false,
      searchInput: "",
      results: [],
      currencyCode: {},
      currencyCo: [],
    };
  },
  computed: {
    // Load data from store
    itemsData() {
      return this.$store.getters.products;
    },
    items() {
      const itemsToIterate = this.searchInput
        ? this.results
        : this.itemsData.unpaginated;

      return itemsToIterate;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    productPlaceholder() {
      return ProductPlaceholder;
    },
    productsPlaceholder() {
      return ProductsPlaceholder;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canAdd() {
      return checkPermissions(
        "create",
        "product",
        this.loggedMemberRoles,
        this.collective
      );
    },
    canList() {
      return checkPermissions(
        "index",
        "product",
        this.loggedMemberRoles,
        this.collective
      );
    },
    canView() {
      return checkPermissions(
        "view",
        "product",
        this.loggedMemberRoles,
        this.collective
      );
    },
  },
  watch: {
    itemImage(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchData();
    await this.fetchCurrencies();
    this.isLoading = false;
  },
  methods: {
    async fetchCurrencies() {
      const response = await this.$store.$service[Service.BackendClient].get(
        "currencies",
        {
          params: {
            communityKey: this.$store.getters.currentCollective.key,
            count: 164,
          },
        }
      );
      this.currencyCode = response.data.data;
      for (let i = 0; i < this.currencyCode.length; i++) {
        this.currencyCo.push(this.currencyCode[i].ISO);
      }
      return response;
    },
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // Call store action (with dispach) to load data from backend
    getImageSrc(url) {
      return getImageResource(url);
    },
    async fetchData(force = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("getItems", {
        itemType: "products",
        page: this.lastLoadedPage,
        forceAPICall: force,
        requestConfig: {
          count: 16,
          orderByDate: -1,
        },
      });
      } catch {

      } finally {
        this.isLoading = false;
      }
    },
    // When the scroll reaches the end, load more data.
    async onScroll(event) {
      const distanceToEnd =
        event.srcElement.scrollWidth -
        event.srcElement.scrollLeft -
        event.srcElement.offsetWidth;
      if (distanceToEnd < 50 && !this.isLoadingNextPage) {
        this.handleLoadOfNewItems();
      }
    },
    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items.length < total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData();
        this.isLoadingNextPage = false;
      } else {
        this.isLoadingNextPage = false;
      }
    },
    // Scroll forward and back (buttons)
    scrollOnePage(direction) {
      const currentScroll = this.$refs.container.scrollLeft;
      const toScroll = this.$refs.container.offsetWidth;
      this.$scrollTo(this.$refs.container, 500, {
        container: this.$refs.container,
        duration: 500,
        offset: currentScroll + direction * toScroll,
        x: true,
        y: false,
      });
    },
    async handleCreateItem(bvModalEvt) {
      // Name is required
      if (!this.productInput.name) {
        bvModalEvt.preventDefault();
        this.formError = false;
        return;
      }
      try {
        await this.$store.dispatch("createItem", {
          item: {
            itemType: "products",
            requestConfig: {
              name: this.productInput.name,
              unitPrice: this.productInput.unitPrice,
              headline: this.productInput.headline,
              description: this.productInput.description,
              currencyCode: this.productInput.currencyCode,
            },
          },
          file: this.itemImage,
        });
        this.fetchData(true);
        this.productInput = {};
        this.formError = null;
        this.itemImage = null;
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t("error-message.general-error"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    searchItems(value) {
      this.searchInput = value;
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      const searchTermToLower = searchTerm.toLowerCase();
      this.results = this.itemsData.unpaginated.filter((item) => {
        const itemNameStr = JSON.stringify(item.name).toLowerCase();
        if(itemNameStr.includes(searchTermToLower)){
          return item;
        }
      });
    },
  },
};
</script>
<style scoped>
.item-img {
  height: 200px !important;
}
.card-standard {
  height: 380px !important;
}
.titulo {
  font-size: 1.2em;
}
.precio {
  font-size: 1.5em;
  font-weight: 900 !important;
}
</style>
